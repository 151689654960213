/* @flow */

module.exports = {
  "APP": {
    "NAME": "Villaägarna shop",
  },
  "LOCALE": {
    "ISO": {
      "639-1": "sv",
      "3166-1": "SE",
      "IETF": "sv-SE",
    },
    "CURRENCY": "SEK",
    "CURRENCY_SHORT": "kr",
    "POINTS": "poäng",
  },
  "META": {
    "GOOGLE_SITE_OWNER": "",
    "HELPDESK_LINK": "https://helpdesk.crossroads.se/?s=15ea2ceb08f03c15ea2ceb08f072",
  },
  "LOGO": {
    "ALT": "Villaägarna",
    "TITLE": "Gå till startsidan",
  },
  "CURRENCY_SHORT": {
    "NOK": "kr",
    "DKK": "kr",
    "SEK": "kr",
    "EUR": "€",
  },
  "BREADCRUMBS": {
    "HOME": "Hem",
    "HOME_URL": "/",
  },
  "HOMEVIEW": {
    "POPULAR_CATEGORIES": "Populära kategorier",
    "FEATURED_PRODUCTS": "Populära produkter",
    "SEE_MORE_FEATURED_PRODUCTS": "Se fler",
    "RECENTLY_VIEWED_PRODUCTS": "Senast besökta",
    "BLOCK_1_TITLE": "Produkter i blickfånget",
    "BLOCK_2_TITLE": "Kampanj",
  },
  "ALL_PRODUCTS": {
    "TITLE": "Produkter",
  },
  "PRODUCTLIST_HERO": {
    "TITLE_PREFIX_EMPTY": "Inga produkter att visa",
    "TITLE_PREFIX_SINGULAR": "Visar 1 produkt",
    "TITLE_PREFIX_PLURAL": "Visar %itemsCount produkter",
    "IN_PRICERANGE": "i prisintervallet",
  },
  "SORT": {
    "OFFCANVAS": {
      "TITLE": "Sortera",
      "SHOW_RESULT": "Visa resultat",
      "CLOSE_MENU": "Stäng sortering",
    },
  },
  "LOGINVIEW": {
    "TITLE": "Logga in",
    "CODE": "Kod",
    "SUBMIT": "Logga in",
  },
  "FILTER": {
    "FILTERBAR": {
      "ALL_FILTERS": "Filtrera",
      "FILTER_BY_POINTS": "Filtrera på poäng",
      "FILTER_BY_PRICE": "Filtrera på pris",
      "PRICE_RANGE": "%min - %max poäng",
      "SORT": "Sortera",
      "SORT_DEFAULT": "Utvalda",
      "SORT_NAME_DOWN": "Produktnamn: Ö-A",
      "SORT_NAME_UP": "Produktnamn: A-Ö",
      "SORT_PRICE_DOWN": "Dyrast",
      "SORT_PRICE_UP": "Billigast",
      "SORT_POSITION_DOWN": "Populärast",
      "SORT_POPULAR_DOWN": "Populärast",
      "SORT_VALUE_DOWN": "Värde: Lågt",
      "SORT_VALUE_UP": "Värde: Högt",
      "SORT_RELEASE_DATE_DOWN": "Senaste",
      "SORT_RELEASE_DATE_UP": "Äldst",
      "SORT_LATEST_DOWN": "Senaste",
      "SORT_MANUFACTURER_DOWN": "Varumärke: A-Ö",
      "SORT_MANUFACTURER_UP": "Varumärke: Ö-A",
    },
    "OFFCANVAS": {
      "TITLE": "Filter",
      "SHOW_RESULT_TOTAL_SINGLE": "%total Produkt",
      "SHOW_RESULT_TOTAL_MULTIPLE": "%total Produkter",
      "SHOW_RESULT": "Visa resultat",
      "CLOSE_MENU": "Stäng filter",
    },
    "ACTIVE_FILTERS": {
      "CLEAR_ALL": "Rensa filter",
      "NO_ACTIVE_FILTERS": "Du har inga aktiva filter",
      "PRICE_RANGE": "Pris (%range)",
      "TOTAL_COUNT": "Visar %count produkter",
    },
    "FILTERMENU": {
      "FILTER_BY_POINTS": "Filtrera på poäng",
      "FILTER_BY_PRICE": "Filtrera på pris",
      "RESET_PRICE": "Rensa filter",
      "PRICE_FROM": "Från",
      "PRICE_TO": "Till",
      "BALANCE_FILTER": "Filtrera på saldo (%value poäng)",
      "WITHIN_BALANCE": "Produkter inom poängsaldo",
    },
  },
  "ADVENT_CALENDAR": {
    "TEXT": "Julkalender",
    "LINK": "/advent-calendar",
    "DOOR_TITLE": "Lucka %day",
    "VIEW_ALL_PRODUCTS": "Visa alla produkter i dagens lucka",
    "BACK": "Tillbaka till julkalendern",
    "SAVE_PERCENTAGE": "Spara upp till %percentage%",
    "CATEGORY_URL": "advent-calendar",
    "OPEN_DOOR": "Öppna lucka",
    "OPEN_DOOR_ALT": "Öppna lucka",
    "CLOSED_DOOR_ALT": "Stängd lucka",
    "CALENDAR_CLOSED": "Julkalendern är stängd",
    "TOTAL_COUNT_EMPTY": "Inga produkter att visa",
    "TOTAL_COUNT_SINGULAR": "Visar 1 produkt",
    "TOTAL_COUNT_PLURAL": "Visar %totalCount produkter",
    "LANDING": {
      "HEADING": "VILLAÄGARNAS MEDLEMSSHOP JULKALENDER",
      "TEXT": "Spännande erbjudanden varje dag fram till julafton, startar första december.",
    },
  },
  "ADDRESS": {
    "FIRSTNAME": "Förnamn",
    "LASTNAME": "Efternamn",
    "STREET_1": "Gatuadress 1",
    "STREET_2": "Gatuadress 2 (frivilligt)",
    "POSTCODE": "Postnummer",
    "CITY": "Stad",
    "COUNTRY": "Land",
    "EMAIL": "E-mail",
    "TELEPHONE": "Telefon",
  },
  "OR": "eller",
  "CART": {
    "HEADING": "Varukorg",
    "EMPTY": "Varukorgen är tom",
    "OF": "av",
    "CTA": "Gå till kassan",
    "ALT": "Ändra i varukorg",
    "EDIT": "Ändra i varukorg",
    "INCREMENT": "Öka kvantitet",
    "DECREMENT": "Minska kvantitet",
    "QUANTITY": "Kvantitet",
    "SHIPPING": "Frakt",
    "SUBTOTAL": "Summa",
    "GRANDTOTAL": "Totalsumma inkl moms",
    "SUMMARY": "Summering",
    "TO_CHECKOUT": "Gå till kassan",
    "REMOVE_PRODUCT": "Ta bort produkt",
    "REMOVE_APPROVE": "Ja, ta bort",
    "CONFIRM_TEXT": "Vill du verkligen ta bort '%itemName' från varukorgen?",
    "MINIMIZE_SUMMARY": "Minimera översikt",
    "VIEW_DETAILS": "Visa detaljer",
    "DISCOUNT": "Rabatt",
  },
  "CONFIRM_DIALOG": {
    "CANCEL": "Avbryt",
  },
  "CHECKOUT": {
    "NEED_HELP": "Behöver du hjälp?",
    "CONTACT_CUSTOMER_SERVICE": "Kontakta kundtjänst",
    "TO_PAYMENT": "Gå till betalning",
    "CHOOSE_PAYMENT_METHOD": "Vidare till betalning",
    "AMOUNT_TO_PAY": "Att betala",
    "CASH": "Kortbetalning",
    "PAY_WITH_CARD": "Betala med kort",
    "CARD_PAYMENT": "Betala med kort",
    "EDIT": "Ändra",
    "PAYMENT_AND_DELIVERY": "Betalning & leverans",
    "SENT_TO": "Skickas till",
    "CONFIRM_ORDER": "Slutför beställning",
    "CONTINUE_SHOPPING": "Fortsätt handla",
    "PAYMENT_TITLE": "Hur vill du betala?",
    "I_ACCEPT_THE": "Jag accepterar",
    "TERMS": "köpvillkoren",
    "ACCORDION_HEADER_CART": "Varukorg",
    "DISCOUNT_CODE": {
      "HEADER": "Rabattkod",
      "CHECKBOX_LABEL": "Jag har en rabattkod",
      "APPLY": "Applicera",
      "REMOVE": "Ta bort",
    },
    "DISCOUNT": "Rabatt (%discount)",
    "ADDRESS": {
      "ADDRESS": "Adress",
      "SHIPPING": "Leveransadress",
      "BILLING": "Kvittoadress",
      "SHIP_TO_SAME_ADDRESS": "Samma adress på kvitto",
      "EMPTY_ADDRESS": "Adressen är inte komplett, vänligen uppdatera",
    },
    "STEPS": {
      "CART": {
        "SHORT": "Varukorg",
        "LONG": "Varukorg",
      },
      "SHIPPING": {
        "SHORT": "Leverans",
        "LONG": "Kontrollera din adress",
      },
      "PAYMENT": {
        "SHORT": "Betala",
        "LONG": "Betala",
      },
      "OVERVIEW": {
        "SHORT": "Betala",
        "LONG": "Översikt",
      },
    },
  },
  "PAYMENT": {
    "USE_CARD": "Betala med kort",
    "USE_SAVED_CARD": "Betala med sparat kort",
  },
  "PAYMENT_METHODS": {
    "APPLE_PAY": "pay",
  },
  "CUSTOMER_SERVICE": {
    "TEXT": "Kundservice",
    "LINK": "/kundservice",
  },
  "SUCCESS": {
    "TITLE": "Beställning registrerad",
    "SUB_TITLE": "Ordernummer %number",
    "NOT_FOUND_TITLE": "Ingen order hittades.",
    "NOT_FOUND_SUBTITLE": "Vänligen kontakta kundtjänst om detta är felaktigt.",
    "BACK": "Återgå till shoppen",
    "VALID_UNTIL": "Giltig t.o.m. %date",
    "WHAT_NOW": "Så vad händer nu?",
    "ORDER_OVERVIEW": "Orderöversikt",
    "CONTACT": {
      "TITLE": "Kontakta oss",
      "CREATE_TICKET": "Skapa ärende",
      "INFO": "Du kan skapa ärende när som helst.</br>Under arbetstid svarar vi vanligtvis</br>inom 1-2 timmar.",
      "CALL_US": "Ring oss",
      "CALL_US_HOURS": "Öppettider:<br />mån-fre: 09.00-12.00, lör-sön: Stängt",
      "CALL_US_PHONENUMBER": "+46 (0)31 700 83 86",
    },
    "STEPS": {
      "NORMAL": {
        "1": {
          "HEADING": "Bekräftelse",
          "TEXT": "En orderbekräftelse kommer att skickas till %email inom kort.",
        },
        "2": {
          "HEADING": "Bearbetning",
          "TEXT": "Din beställning skickas när samtliga produkter finns tillgängliga för leverans. Vanligen sker detta redan nästkommande dag.",
        },
        "3": {
          "HEADING": "Transport",
          "TEXT": "När dina varor packats och skickats så meddelas du via e‑post. I leveransbekräftelsen finner du en spårningslänk med vilken du kan följa paketets väg. Leveransen tar normalt 2–5 dagar.",
        },
        "4": {
          "HEADING": "Leverans",
          "TEXT": "Vissa paket levereras direkt i din brevlåda. Paket som är för stora levereras i stället till ett utlämningsställe och aviseras via sms till det mobilnummer du har angivit i beställningen. Paket ligger kvar på utlämningsstället i 14 dagar.",
        },
      },
      "MIXED": {
        "1": {
          "HEADING": "Bekräftelse",
          "TEXT": "En orderbekräftelse och dina digitala biljetter/värdekoder kommer att skickas till %email inom kort. Har du inte fått någon e‑post ber vi dig dubbelkolla ditt spam-filter innan du kontaktar kundservice.",
        },
        "2": {
          "HEADING": "Bearbetning",
          "TEXT": "Din beställning skickas när samtliga produkter finns tillgängliga för leverans. Vanligen sker detta redan nästkommande dag.",
        },
        "3": {
          "HEADING": "Transport",
          "TEXT": "När dina varor packats och skickats så meddelas du via e‑post. I leveransbekräftelsen finner du en spårningslänk med vilken du kan följa paketets väg. Leveransen tar normalt 2–5 dagar.",
        },
        "4": {
          "HEADING": "Leverans",
          "TEXT": "Vi skickar dina digitala biljetter/värdekoder till %email inom kort. Din fysiska leverans skickas med DBSchenker. Vissa paket levereras direkt i din brevlåda. Paket som är för stora levereras i stället till ett utlämningsställe och aviseras via sms till det mobilnummer du har angivit i beställningen. Paket ligger kvar på utlämningsstället i 14 dagar.",
        },
      },
      "VIRTUAL": {
        "1": {
          "HEADING": "Bekräftelse",
          "TEXT": "Du kommer att få en orderbekräftelse till %email.",
        },
        "2": {
          "HEADING": "Digital leverans",
          "TEXT": "Vi skickar dina digitala biljetter/värdekoder till %email inom kort. Har du inte fått någon e‑post ber vi dig dubbelkolla ditt spam-filter innan du kontaktar kundservice.",
        },
      },
    },
  },
  "SERIAL_CODE": {
    "FETCH_STATUS": {
      "LOADING": "Vänta medan vi laddar din värdekod",
      "ERROR": "An error occured. Your code is sent to your e-mail",
    },
  },
  "CATEGORY": {
    "PRODUCTS_IN_CATEGORY_TITLE_SINGULAR": "Visar 1 produkt",
    "PRODUCTS_IN_CATEGORY_TITLE_PLURAL": "Visar %itemsCount produkter",
  },
  "PRODUCTLIST": {
    "EMPTY": "Det finns inga produkter att visa just nu",
  },
  "POPULAR": {
    "POPULAR_PRODUCTS": "Populära produkter",
  },
  "404": {
    "TITLE": "Sidan kunde inte hittas",
    "HEADING": "— 404 —",
    "SUBHEADING": "Sidan kunde inte hittas",
  },
  "SERVER_ERROR": {
    "TITLE": "Serverfel",
    "HEADING": "Serverfel: 500",
    "SUBHEADING": "Vänligen återkom inom kort!",
    "TEXT": "<strong>Behöver du kontakta oss?</strong><br/> Skicka ett mail till <a href=\"mailto:kundservice@crossroads.se\">kundservice@crossroads.se</a>",
  },
  "SEARCH": {
    "TITLE_PREFIX": "Sök: ",
    "PLACEHOLDER": "Sök i sortimentet",
    "RESULTS": "Resultat för sökningen \"%query\"",
    "TYPE": {
      "CATEGORY": "Kategori",
      "PRODUCT": "Produkt",
      "BRAND": "Varumärke",
    },
  },
  "HEADER": {
    "NAVIGATION": "Navigation",
    "SEARCH": "Sök",
    "CATEGORIES": "Kategorier",
    "BRANDS": "Varumärken",
    "CART": "Kassan",
  },
  "FOOTER": {
    "LINKS": {
      "TITLE": "Länkar",
      "START": "Hem",
      "CATEGORIES": "Kategorier",
      "BRANDS": "Varumärken",
      "SEARCH": "Sök i sortimentet",
    },
    "NEED_HELP": {
      "TITLE": "Behöver du hjälp?",
      "CONTACT": "Kontakta oss",
    },
    "STAY_IN_TOUCH": {
      "TITLE": "Håll kontakten",
      "FACEBOOK": "Facebook",
      "INSTAGRAM": "Instagram",
      "YOUTUBE": "Youtube",
      "TWITTER": "Twitter",
      "NEWSLETTER": "Anmäl dig till nyhetsbrev",
    },
    "COOKIES": "Hantera cookies",
    "TERMS": "Villkor",
    "PRIVACY_POLICY": "Integritetspolicy",
    "COPYRIGHT": "Copyright © %year <a href=\"https://www.villaagarna.se/\" target=\"_blank\" rel=\"noopener\">Villaägarnas Riksförbund</a>",
  },
  "PRODUCT": {
    "ADD_TO_CART": "Lägg i varukorg",
    "OUT_OF_STOCK": "Ej i lager",
    "OTHERS_ALSO_LIKED": "Andra gillade också",
    "OPTION_DROPDOWN_PLACEHOLDER": "Välj ett val",
    "CHANGE_IMAGE": "Byt bild",
    "GO_TO_MANUFACTURER": "Se alla produkter från %brand",
    "ABOUT_BRAND": "Om %brand",
    "COLOR": "Färg:",
    "CHOOSE_A_COLOR": "Välj en färg",
    "DESCRIPTION": "Produktbeskrivning",
    "SALE": "REA",
    "MSRP": "Rek. pris",
    "DISCOUNT_SHORT": "-%discount%",
  },
  "STRIPE": {
    "PROCESSING": "Laddar...",
    "CARD_NUMBER": "Kortnummer",
    "MM/YY": "MM/YY",
    "CVC": "CVC",
  },
  "COOKIE_CONSENT": {
    "ALL": "Tillåt alla",
    "NECESSARY": "Tillåt nödvändiga",
    "SELECTED": "Tillåt urval",
    "TEXT": "För att förbättra och anpassa din upplevelse av våra digitala tjänster använder vi cookies. Om du vill veta mer om cookies, läs vår ",
    "LINK": "/privacy-policy",
    "LINK_TEXT": "integritetspolicy",
  },
  "COOKIE_OPTIONS": {
    "NECESSARY": "Nödvändiga",
    "STATISTICAL": "Statistiska",
    "MARKETING": "Marknadsföring",
  },
  "VALIDATION": {
    "REQUIRED": "*",
    "EMAIL": "Vänligen fyll i en korrekt e‑postadress.",
    "errorInvalidEmail": "Vänligen fyll i en korrekt e‑postadress.",
    "errorEmailExists": "Emailadressen du angav används redan av ett annat konto.",
    "PHONE": "Vänligen fyll i ett korrekt mobiltelefonnummer.",
    "NUMERIC": "Fältet får bara innehålla nummer",
    "LENGTH_LT": "Fältet innehåller för många tecken",
    "POSTCODE": "Vänligen fyll i ett korrekt postnummer. (XXX XX)",
  },
  "STATUS_CODE": {
    "UNKNOWN": "Ett okänt fel har uppstått. Vänligen ladda om sidan och försök igen.",
    "NETWORK_ERROR": "Anropet avbröts. Vänligen verifiera att du har en aktiv internetanslutning eller försök igen om en stund.",
    "PLACE_ORDER_CATCH": "Ett fel uppstod. Vänligen försök igen senare.",
    "DISCOUNT_CODE_APPLIED": "Rabattkod applicerad.",
    "DISCOUNT_CODE_FAILURE": "Rabattkoden kunde inte appliceras, vänligt försök igen eller kontakta kundtjänst.",
    "errorUnknownProduct": "Okänd produkt, vänligen försök igen.",
    "errorNoPaymentMethod": "Ingen betalmetod vald.",
    "errorProductQuantityNotAvailable": "Det antal du angav finns inte tillgängligt just nu.",
    "errorProductNotInStock": "Produkten du försökte lägga i varukorgen finns dessvärre inte i lager just nu.",
    "errorProductMaxQuantity": "Det angivna antalet finns dessvärre inte i lager just nu.",
    "errorProductNotChosenVariant": "Vänligen välj %attributeLabel för att kunna lägga till produkten i din varukorg.",
    "termsNotAccepted": "Du måste godkänna köpvillkoren för att genomföra ditt köp.",
    "errorNotLoggedIn": "Du är inte inloggad, vänligen logga in.",
    "errorInvalidState": "Något gick fel, vänligen prova igen.",
    "errorInvalidLogin": "Felaktiga inloggningsuppgifter",
    "errorMissingCustomer": "Kunden du försöker logga in som finns inte",
    "errorBlacklistedEmail": "Emailadressen du angav används redan av ett annat konto. Vänligen ange en annan.",
    "errorEmailExists": "Emailadressen du angav används redan av ett annat konto. Vänligen ange en annan.",
    "errorInvalidEmailOrPassword": "Fel epostadress eller lösenord",
    "errorInvalidEmail": "Ogiltig Emailadress",
    "Your card number is incomplete": "Vänligen fyll i dina kortuppgifter.",
    "SUCCESSFULLY_LOGGED_IN": "Du är nu inloggad",
    "LOGIN_ERROR": "Inloggningen misslyckades. Vänligen försök igen eller vänta och testa igen om en stund.",
    "YOUR_CARD_HAS_INSUFFICIENT_FUNDS": "Du saknar täckning på kortet.",
    "YOUR_CARDS_SECURITY_CODE_IS_INCORRECT": "CVC koden för kortet är inte korrekt.",
    "YOUR_CARD_WAS_DECLINED": "Kortet nekades.",
    "YOUR_CARD_HAS_EXPIRED": "Kortet är utgånget.",
    "AN_ERROR_OCCURRED_WHILE_PROCESSING_YOUR_CARD_TRY_AGAIN_IN_A_LITTLE_BIT": "Ett fel inträffade, försök igen om en liten stund.",
    "WE_ARE_UNABLE_TO_AUTHENTICATE_YOUR_PAYMENT_METHOD_PLEASE_CHOOSE_A_DIFFERENT_PAYMENT_METHOD_AND_TRY_AGAIN": "Vi kunde inte godkänna betalningsmetoden. Vänligen försök igen.",
    "INTERNAL_SERVER_ERROR": "Internt serverfel.",
    "CODE_COPIED": "Värdekoden kopierades till urklippet.",
    "CODE_NOT_COPIED": "Värdekoden kunde inte kopieras.",
    "errorMaxAttemptsReached": "För många anrop. Vänligen vänta några minuter och prova igen.",
    "errorPointRedemptionNotAllowed": "Köp med poäng är avstängt på ditt konto just nu.",
    "invalid_sso_token_or_otp": "Felaktig OTP. Vänligen försök igen",
    "invalid_sso_ticket": "Din session löpte ut. Vänligen logga in igen.",
    "errorNoTicket": "Din session löpte ut. Vänligen logga in igen.",
    "ITEM_REMOVED_FROM_CART": "%name togs bort från varukorgen eftersom att den inte längre finns i lager.",
  },
};
